import VScode from '../images/VS-Code.webp'
import Git from '../images/GIT.webp'
import Github from '../images/github.webp'
import Figma from '../images/figma.webp'
import React from '../images/react.webp'

const technoTool =[
    {
        "image": VScode,
        "name": "VS Code"
      },
      {
        "image": Git,
        "name": "GIT"
      },
      {
        "image": Github,
        "name": "Github"
      },
      {
        "image": Figma,
       "name":"Figma"
     },
     {
       "image":React,
       "name":"React-JS"
     },
     // {
     //   "image": React,
     //   "name":"react-native"
     // }
];

export default technoTool;