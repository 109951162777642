
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import SwiperNavButtons from '../SwiperNavButtons/SwiperNavButtons'

function Diaporama({ images }) {

    return (
        <article className='diaporama'>
            <Swiper
            modules={[Pagination, Navigation]}
            spaceBetween={50}
            slidesPerView={1}
            loop={true}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
            pagination={{ clickable: true }}
            >
                {images.map((image, index) => (
                    <SwiperSlide key={index}>
                        <img src={image} alt='diapo'/>   
                    </SwiperSlide>
                ))}
                {images.length > 1 ?  <SwiperNavButtons /> : null}
            </Swiper>
        </article>

    )
}

export default Diaporama