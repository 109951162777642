import booki_preview from '../images/booki.webp'
import ohMyFood_preview from '../images/ohmyfood.webp'
import ohMyFood_preview_2 from '../images/OhMyFood_2.webp'
import PrintIt_preview from '../images/printit.webp'
import SophieBluel_preview from '../images/Sophie_Bluel.webp'
import SophieBluel_preview_2 from '../images/Sophie_Bluel_2.webp'
import SophhieBluel_preview_3 from '../images/Sophie_Bluel_3.webp'
import Kasa_preview from '../images/Kasa.webp'
import Kasa_preview_2 from '../images/Kasa_2.webp'

const works = [
    {
        "id":"1",
        "name":"Booki",
        "readme_short":"Projet réalisé au cours de ma formation. Site statique entièrement en CSS et HTML.",
        "preview":booki_preview,
        "diapo" : [
            booki_preview,
        ],
        "githubLink":"https://github.com/Anthax386/Booki-Projet-2.git",
        "website": 'https://booki-kevinb.netlify.app/',
        "display": true
    },
    {
        "id":"2",
        "name":"ohMyFood",
        "readme_short":"Projet réalisé lors de ma formation. Site statique avec des animation CSS.",
        "preview":ohMyFood_preview,
        "diapo" : [
            ohMyFood_preview,
            ohMyFood_preview_2
        ],
        "githubLink":"https://github.com/Anthax386/ohMyFood-Projet4.git",
        "website": "https://ohmyfood-kevinb.netlify.app",
        "display": true
    },
    {
        "id":"3",
        "name":"PrintIt",
        "readme_short":"Projet réalisé lors de ma formation. Introduction du JavaScript.",
        "preview":PrintIt_preview,
        "diapo": [
            PrintIt_preview,
        ],
        "githubLink":"https://github.com/Anthax386/Booki-Projet-2.git",
        "website": "https://printit-kevinb.netlify.app",
        "display": false
    },
    {
        "id":"4",
        "name":"Portefolio Sophie Bluel",
        "readme_short":"Projet réalisé au cours de ma formation. Site dynamique complet, relié à une base de donnée via une API.",
        "preview":SophieBluel_preview,
        "diapo": [
            SophieBluel_preview,
            SophieBluel_preview_2,
            SophhieBluel_preview_3
        ],
        "githubLink":"https://github.com/Anthax386/Projet_6_Portfolio_Sophie_Bluel.git",
        
        "display": true
    },{
        "id":"5",
        "name":"Kasa",
        "readme_short":"Projet réalisé lors de ma formation. Application web utilisant le framework ReactJS.",
        "preview":Kasa_preview,
        "diapo": [
            Kasa_preview,
            Kasa_preview_2
        ],
        "githubLink":"https://github.com/Anthax386/OC-Projet8-Kasa",
        "website": "https://kasa-kevinb.netlify.app",
        "display": true
    }
]

export default works