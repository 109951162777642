import splitBanner from '../../images/informatique.jpg'

function SplitBanner({ title }) {
  return (
    <div className="splitBanner">
      <h2 className='splitBanner_title'>{title}</h2>
      <img src={splitBanner} alt="split banner" className='splitBanner_img' />
    </div>
  );
};

export default SplitBanner;