import './Works.scss'

import Diaporama from '../../components/Diaporama/Diaporama'

export default function  Works({work}){

    return (
        <article className="work-card" id={work.id}>
            <h2 className="work-card_title">{work.name}</h2>
            
            
            <div className='work-card_preview'>
                <Diaporama images={work.diapo} />
            </div>
            <div className="work-card_content">
                <p className="work-card_content_readme">{work.readme_short}</p>
                {work.website ? 
                <a
                    className='work-card_content_link' 
                    href={work.website} 
                    target="_blank" 
                    rel="noreferrer"
                >Site</a> 
                : null
                }
                <a
                    className="work-card_content_link"
                    href={work.githubLink}
                    target="_blank"
                    rel="noreferrer"
                >Github</a>
            </div>
        </article>
    )
}