import banner from '../../images/banner_developer-.jpg'

function Header() {
    return(
        <section className='header'>
            <img src={banner} alt="banner" className='backgroundImg'/>
            <h1 className='title'>
                <span className='title-name'>BRUNET Kevin</span>
                <span className='title-job'>Developpeur Front-End</span> 
            </h1>
        </section>
    );
};

export default Header;