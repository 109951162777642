import './Home.scss';

import works from '../data/works.js'
import technoLang from '../data/technoLang.js'
import technoTool from '../data/technoTool.js'
import Works from '../components/Works/Works'
import Techno from '../components/Techno/Techno.jsx';
import SplitBanner from '../components/SplitBanner/SplitBanner.jsx';
import About from '../components/About/About.jsx';
// import ContactForm from '../components/ContactForm/ContactForm.jsx';

function Home() {

  return (
    <div className="home">
      <section className='aboutMe'>
        <About />
      </section>
      <SplitBanner title='PROJETS' />
      <section className='works'>
        {works.map((e, i) => {
          return e.display && <div key={i}><Works work={e} /></div>
        })}
      </section>
      <SplitBanner title='COMPETENCES' />
      <section className='skills'>
          <div className='skills_under'>
            {technoLang.map((e, i) => {
              return <div key={i}><Techno tech={e} /></div>
            })}
          </div>
          <div className='skills_under'>
            {technoTool.map((e, i) => {
              return <div key={i}><Techno tech={e}/></div>
            })}
          </div>
      </section>
      {
      /* <SplitBanner title='CONTACT' />
      <section className='contact'>
        <div className='contact_form'>
          <ContactForm />
        </div> 
      </section> */
      }
    </div>
  );
};

export default Home;